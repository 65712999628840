.black {
  color: #333; }

.white {
  color: #FFF; }

.blueBg {
  background: #009EE3; }

.whiteBg {
  background: #009EE3; }

.greyBg {
  background: #F8F8F8; }

.centre {
  text-align: center; }

.left {
  text-align: left; }

.right {
  text-align: right; }

.fltLeft {
  float: left; }

.fltRight {
  float: right; }

.nomargin {
  margin: 0; }

.st0, .st1, .st2 {
  fill: #79B829;
  stroke: #79B829;
  stroke-width: 4;
  stroke-miterlimit: 10; }

p {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  line-height: 1.425rem; }
  p a {
    transition: 0.2s ease-in-out; }

.hfont {
  font-family: "jubilat", serif; }

.bfont {
  font-family: "Nunito", sans-serif; }

.title {
  color: #79B829;
  text-align: center;
  font-size: 40px;
  line-height: 42px;
  margin: 0; }

.intro {
  color: #B0B0B0;
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 10px; }

h1, h2, h3, h4, h5, h6 {
  font-family: "jubilat", serif; }

h1.hide {
  display: none; }

h3 {
  font-size: 46px;
  color: #333;
  line-height: 48px; }

.quote {
  font-family: "jubilat", serif;
  font-size: 26px;
  line-height: 28px;
  font-style: italic;
  font-weight: 500; }
  @media screen and (min-width: 40em) {
    .quote {
      font-size: 36px;
      line-height: 38px; } }

.mt0 {
  margin-top: 0; }

.m40 {
  margin: 40px; }

.mt40 {
  margin-top: 40px; }

.m60 {
  margin: 60px; }

.mt60 {
  margin-top: 60px; }

.mb40 {
  margin-bottom: 40px; }

.mb60 {
  margin-bottom: 60px; }

.fullWidth {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: initial; }

.btn {
  padding: 15px 30px;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  font-family: "Nunito", sans-serif;
  display: inline-block;
  outline: none; }
  .btn:active {
    transform: scale(0.97); }
  .btn.border {
    border: 1px solid; }
    .btn.border:hover.white_border {
      background: #FFF;
      border-color: #FFF;
      color: #003735; }
  .btn.blueBg:hover {
    background: #0068B3; }
  .btn.greenBg {
    background: #79B829;
    color: #FFF; }
    .btn.greenBg:hover {
      background: #6ea724; }
  .btn.orangeBg {
    background: #E84D0E;
    color: #FFF; }
    .btn.orangeBg:hover {
      background: #db470c; }
  .btn.whiteBg {
    background: #FFF;
    color: #79B829; }
    .btn.whiteBg:hover {
      opacity: 0.8; }
  .btn.purpleBg {
    background: #B0107D;
    color: #FFF; }
    .btn.purpleBg:hover {
      background: #960c6a; }
  .btn.purpleDonateBg {
    background: #B0107D;
    color: #FFF; }
    .btn.purpleDonateBg:hover {
      background: #960c6a; }
  .btn.orangeDonateBg {
    background: #E84D0E;
    color: #FFF; }
    .btn.orangeDonateBg:hover {
      background: #db470c; }
  .btn.centerBtn {
    margin: 0 auto;
    text-align: center;
    display: table; }

.donationTypeContainer {
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 60px; }
  @media screen and (min-width: 40em) {
    .donationTypeContainer {
      margin-top: 60px; } }
  .donationTypeContainer .donationType {
    display: inline-block;
    border: 1px solid #F0F0F1;
    color: #79B829;
    font-family: "Nunito", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 20px;
    cursor: pointer;
    transition: 0.2s ease-in-out; }
    .donationTypeContainer .donationType:hover {
      background: #3d5263;
      color: #FFF;
      border-color: #3d5263; }
    .donationTypeContainer .donationType#oneoffDonation {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .donationTypeContainer .donationType#monthlyDonation {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .donationTypeContainer .donationType.active {
      background: #4C6172;
      color: #FFF;
      border-color: #4C6172; }

*::-moz-selection {
  background-color: #009EE3;
  color: #FFF; }

*::selection {
  background-color: #009EE3;
  color: #FFF; }

.fullWidth {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: initial; }

html.noscroll {
  overflow: hidden; }

body {
  overflow-x: hidden;
  transition: .4s ease-in-out;
  background: #FFF;
  margin: 0;
  max-width: 100%; }
  @media screen and (min-width: 64em) {
    body:before {
      transition: .5s ease-in-out;
      content: '';
      position: fixed;
      z-index: 11;
      top: 0;
      left: 0;
      background: rgba(121, 184, 41, 0);
      width: 100%;
      pointer-events: none;
      height: 100%; } }
  body.nav-open {
    overflow: hidden !important; }
    @media screen and (max-width: 39.9375em) {
      body.nav-open {
        position: fixed;
        overflow-y: hidden; } }
    body.nav-open:before {
      background: rgba(121, 184, 41, 0.8);
      pointer-events: all; }
    body.nav-open .mainContent {
      overflow: hidden; }

.hide {
  display: none !important; }

.absolute {
  position: absolute; }

.caps {
  text-transform: uppercase; }

.border-top {
  position: relative;
  padding-top: 25px; }
  .border-top:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 140px;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #FFF; }

@media screen and (min-width: 40em) {
  .alternateColumns:nth-child(odd) .alternateLeft {
    float: left; }
  .alternateColumns:nth-child(odd) .alternateRight {
    float: right; }
  .alternateColumns:nth-child(even) .alternateLeft {
    float: right; }
  .alternateColumns:nth-child(even) .alternateRight {
    float: left; } }

.mainContainer {
  margin-top: 60px; }
  @media screen and (min-width: 40em) {
    .mainContainer {
      margin-top: 120px; } }

.bodyCopy {
  color: #FFF;
  position: relative;
  padding-bottom: 20px; }

.relatedArticles {
  margin-top: 80px;
  padding: 40px 0;
  border-top: 1px solid #F0F0F1; }
  .relatedArticles h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 60px; }

.noArticles h2 {
  font-family: "Nunito", sans-serif;
  font-style: italic;
  color: #333;
  font-weight: 400; }

.noUpcomingArticles {
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-style: italic;
  font-weight: 400;
  margin-top: 60px; }

.newsInclude {
  margin-top: 40px;
  padding: 40px 0;
  background: #F8F8F8; }

.reveal {
  overflow: visible;
  border-radius: 0;
  padding-bottom: 60px; }
  @media screen and (max-width: 40em) {
    .reveal {
      overflow-y: scroll; } }
  .reveal .close-button {
    position: fixed;
    bottom: 20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    background: #FF0000;
    width: 30px;
    height: 30px;
    line-height: 0px;
    font-size: 24px;
    text-align: center;
    padding: 0;
    border-radius: 0;
    transition: 0.2s ease-in-out;
    cursor: pointer; }
    @media screen and (min-width: 40em) {
      .reveal .close-button {
        position: absolute;
        top: 0;
        right: -35px;
        left: auto; } }
    .reveal .close-button span {
      color: #FFF; }
    .reveal .close-button:hover {
      cursor: pointer;
      opacity: 0.6; }
  .reveal img {
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-top: 20px; }
  .reveal h3 {
    margin: 30px 0 0; }
  .reveal h5 {
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 300;
    text-transform: uppercase; }

#map {
  height: 400px; }

header {
  width: 100%;
  z-index: 1111;
  display: block;
  position: relative;
  padding: 15px 0;
  background: #FFF; }
  @media screen and (min-width: 40em) {
    header {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding-top: 20px;
      padding: auto;
      background: none; } }
  header .logoContainer a {
    line-height: 63px;
    text-decoration: none; }
    header .logoContainer a img {
      vertical-align: middle;
      max-height: 70px; }
      @media screen and (min-width: 40em) {
        header .logoContainer a img {
          max-height: 80px; } }
  header .btn {
    background: #B0107D;
    color: #FFF;
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: 28px;
    right: 75px;
    float: right; }
    header .btn:hover {
      background: #960c6a; }
    @media screen and (min-width: 40em) {
      header .btn {
        background: #79B829;
        top: 35px; }
        header .btn:hover {
          background: #6ea724; } }
    @media screen and (min-width: 64em) {
      header .btn {
        right: 0;
        position: relative;
        top: 15px; } }

.newsInclude + footer {
  margin-top: 0; }

.neverthelessContainer + footer {
  margin-top: 0; }

.neverthelessContainer + .newsInclude {
  margin-top: 0; }

footer {
  background: #4C6172;
  margin-top: 60px;
  padding-top: 40px; }
  footer h4 {
    color: #FFF;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 500; }
  footer .addressDetails {
    font-size: 14px; }
    footer .addressDetails span {
      font-family: "Nunito", sans-serif;
      font-size: 16px;
      display: block;
      clear: both;
      line-height: 16px;
      color: #FFF;
      text-transform: capitalize; }
    footer .addressDetails span + span {
      margin-top: 10px; }
    @media screen and (max-width: 39.9375em) {
      footer .addressDetails {
        margin-top: 30px; } }
  footer .footerNavigation .menu ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0; }
    footer .footerNavigation .menu ul li a {
      padding: 0;
      color: #FFF;
      transition: 0.2s ease-in-out;
      font-size: 16px;
      line-height: 16px; }
      footer .footerNavigation .menu ul li a:hover {
        color: #79B829; }
    footer .footerNavigation .menu ul li + li {
      margin-top: 10px; }
  footer .contactLinks {
    margin-top: 20px;
    text-align: left; }
    footer .contactLinks span {
      font-family: "Nunito", sans-serif;
      font-size: 16px;
      display: block;
      clear: both;
      line-height: 16px;
      color: #FFF;
      text-transform: capitalize; }
      footer .contactLinks span .noCaps {
        text-transform: none;
        display: inline-block; }
        footer .contactLinks span .noCaps a {
          color: #FFF;
          text-decoration: underline; }
    footer .contactLinks span + span {
      margin-top: 10px; }
  footer .charityDetails {
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    display: block;
    clear: both;
    line-height: 16px;
    color: #FFF; }

.copyrightBanner {
  background: #3d5263;
  margin-top: 40px;
  padding: 15px 0; }
  .copyrightBanner span {
    color: #FFF;
    font-size: 14px; }
    .copyrightBanner span a {
      color: #FFF;
      text-decoration: underline; }
      .copyrightBanner span a:hover {
        text-decoration: none; }

.socialMedia {
  margin-top: 20px; }
  .socialMedia span + span {
    margin-left: 15px; }
  .socialMedia .socialIcon {
    color: #FFF;
    font-size: 1.5rem;
    transition: 0.2s ease-in-out; }
    .socialMedia .socialIcon:hover {
      opacity: 0.8; }
    .socialMedia .socialIcon:active {
      transform: scale(0.97); }

.mainNavigation {
  transition: .3s ease-in-out;
  position: relative;
  padding: 0;
  background: none; }
  .mainNavigation .menu > ul {
    padding: 0; }
    .mainNavigation .menu > ul li {
      font-family: "Nunito", sans-serif; }
      .mainNavigation .menu > ul li a {
        font-family: "Nunito", sans-serif;
        color: #FFF;
        font-weight: 500;
        font-size: 1.125rem; }
      .mainNavigation .menu > ul li.parent > a {
        position: relative;
        padding: 10px 25px 10px 10px; }
        .mainNavigation .menu > ul li.parent > a:after {
          content: "\f3d0";
          font-family: "Ionicons";
          position: absolute;
          top: 15px;
          right: 10px;
          font-size: 13px;
          color: #FFF; }
      .mainNavigation .menu > ul li > ul {
        padding: 0; }
        .mainNavigation .menu > ul li > ul li {
          display: block; }
          .mainNavigation .menu > ul li > ul li a {
            font-size: 0.9rem; }
      .mainNavigation .menu > ul li:hover {
        background: #FFF; }
        .mainNavigation .menu > ul li:hover a {
          color: #79B829; }
          .mainNavigation .menu > ul li:hover a:after {
            color: #79B829; }
    .mainNavigation .menu > ul li + li {
      display: inline-block; }
  @media screen and (min-width: 64em) {
    .mainNavigation .menu > ul {
      display: inline-block; }
      .mainNavigation .menu > ul li {
        display: inline-block;
        position: relative; }
        .mainNavigation .menu > ul li a {
          display: block; }
        .mainNavigation .menu > ul li > ul {
          display: none;
          position: absolute;
          background: #FFF;
          left: 0;
          margin: 0;
          width: 150%; }
          .mainNavigation .menu > ul li > ul li {
            display: block;
            width: 100%; }
            .mainNavigation .menu > ul li > ul li a {
              display: block;
              padding: 10px 15px;
              cursor: pointer; }
            .mainNavigation .menu > ul li > ul li:hover a {
              background: #F8F8F8; }
        .mainNavigation .menu > ul li:hover {
          cursor: pointer; }
          .mainNavigation .menu > ul li:hover a {
            background: #FFF;
            color: #79B829; }
          .mainNavigation .menu > ul li:hover > ul {
            display: inline; } }

section[role="mega-menu"] {
  background: #4C6172;
  padding: 30px 0 60px;
  border-top: 1px solid #F0F0F1;
  position: fixed;
  width: 100%;
  z-index: 111;
  top: -100%;
  transition: .5s ease-in-out;
  height: 100%;
  overflow: hidden; }
  @media screen and (max-width: 63.9375em) {
    section[role="mega-menu"] {
      top: -150%;
      height: calc(100% - 63px); }
      section[role="mega-menu"] .row {
        height: 100%; }
        section[role="mega-menu"] .row .columns {
          height: 100%; }
          section[role="mega-menu"] .row .columns .megaMenu {
            height: 100%;
            overflow-y: scroll; } }
  @media screen and (min-width: 64em) {
    section[role="mega-menu"] {
      background: #FFF; } }
  section[role="mega-menu"].open {
    top: 100px; }
    @media screen and (min-width: 64em) {
      section[role="mega-menu"].open {
        top: 120px; } }
  @media screen and (min-width: 64em) {
    section[role="mega-menu"] {
      padding: 30px 20px;
      top: -100%; } }
  @media screen and (max-width: 63.9375em) {
    section[role="mega-menu"] .columns {
      padding: 0; } }
  section[role="mega-menu"] .menu > ul {
    margin: 0;
    padding: 0;
    width: 100%; }
    section[role="mega-menu"] .menu > ul > li {
      width: 100%;
      display: block;
      float: left;
      position: relative; }
      section[role="mega-menu"] .menu > ul > li.parent:after {
        content: '\f3d0';
        font-family: "Ionicons";
        position: absolute;
        transform: rotate(0deg);
        right: 25px;
        top: 8px;
        color: #FFF;
        cursor: pointer;
        transition: 0.2s ease-in-out; }
      @media screen and (min-width: 64em) {
        section[role="mega-menu"] .menu > ul > li {
          width: 20%; }
          section[role="mega-menu"] .menu > ul > li:after {
            display: none; } }
      section[role="mega-menu"] .menu > ul > li > a {
        font-size: 1.2rem;
        text-decoration: none;
        transition: .2s ease-in-out;
        color: #FFF;
        width: 85%;
        padding: 10px 20px;
        font-family: "Nunito", sans-serif;
        font-weight: 500; }
        section[role="mega-menu"] .menu > ul > li > a:hover {
          color: #79B829; }
        @media screen and (min-width: 64em) {
          section[role="mega-menu"] .menu > ul > li > a {
            width: 100%;
            padding: 0; } }
      section[role="mega-menu"] .menu > ul > li.open:after {
        transform: rotate(180deg); }
      section[role="mega-menu"] .menu > ul > li.open .sub-menu {
        height: auto;
        transition: 0.2s ease-in-out; }
      section[role="mega-menu"] .menu > ul > li .sub-menu {
        list-style-type: none;
        padding: 0;
        height: 0;
        overflow: hidden;
        transition: 0.2s ease-in-out;
        background: #3d5263; }
        @media screen and (min-width: 64em) {
          section[role="mega-menu"] .menu > ul > li .sub-menu {
            margin-top: 10px;
            height: auto;
            background: none; } }
        section[role="mega-menu"] .menu > ul > li .sub-menu li {
          margin: 0; }
          section[role="mega-menu"] .menu > ul > li .sub-menu li a {
            color: #FFF;
            transition: 0.2s ease-in-out;
            font-size: 0.9rem;
            padding: 15px 20px 15px 35px; }
            section[role="mega-menu"] .menu > ul > li .sub-menu li a:hover {
              color: #79B829;
              padding-left: 50px; }
            @media screen and (min-width: 64em) {
              section[role="mega-menu"] .menu > ul > li .sub-menu li a {
                padding: 5px 0; }
                section[role="mega-menu"] .menu > ul > li .sub-menu li a:hover {
                  padding-left: 0; } }
    section[role="mega-menu"] .menu > ul li + li {
      margin-top: 10px; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .nav-open header {
    transition: 0.2s ease-in-out;
    background: #4C6172; }
    .nav-open header #menu-icon span {
      background: #FFF; } }

.menu-icon_container {
  display: none; }

#menu-icon {
  width: 30px;
  height: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: .5s ease-in-out;
  cursor: pointer;
  margin: 0;
  right: 20px; }
  #menu-icon span {
    display: block;
    position: absolute;
    height: 1.5px;
    width: 85%;
    background: #333;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out; }
    @media screen and (min-width: 40em) {
      #menu-icon span {
        background: #FFF; } }
    #menu-icon span:nth-child(1) {
      top: 0px; }
    #menu-icon span:nth-child(2) {
      width: 100%; }
    #menu-icon span:nth-child(2), #menu-icon span:nth-child(3) {
      top: 8px; }
    #menu-icon span:nth-child(4) {
      top: 16px; }

#menu-icon.open span:nth-child(1) {
  top: 16px;
  width: 0;
  left: 50%;
  opacity: 0; }

#menu-icon.open span:nth-child(2) {
  transform: rotate(45deg);
  width: 85%; }

#menu-icon.open span:nth-child(3) {
  transform: rotate(-45deg); }

#menu-icon.open span:nth-child(4) {
  top: 16px;
  width: 0;
  left: 50%;
  opacity: 0; }

.footerNavigation {
  margin-bottom: 30px;
  display: -ms-grid;
  display: grid; }
  .footerNavigation > ul {
    margin: 0;
    padding: 0; }
    .footerNavigation > ul > li {
      width: 50%;
      display: inline-block;
      float: left;
      padding-right: 20px; }
      @media screen and (max-width: 39.9375em) {
        .footerNavigation > ul > li {
          margin-top: 20px; } }
      @media screen and (min-width: 40em) {
        .footerNavigation > ul > li {
          width: 25%; } }
      .footerNavigation > ul > li > a {
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        transition: .2s ease-in-out;
        color: #FFF; }
        .footerNavigation > ul > li > a:hover {
          color: #79B829; }
      .footerNavigation > ul > li ul {
        margin: 0;
        padding: 0;
        text-align: left; }
        .footerNavigation > ul > li ul li {
          display: block;
          clear: both;
          float: none;
          line-height: 25px; }
          .footerNavigation > ul > li ul li a {
            font-size: 14px;
            text-decoration: none;
            font-weight: 400;
            transition: .2s ease-in-out;
            color: #FFF; }
            .footerNavigation > ul > li ul li a:hover {
              color: #79B829; }

.featuredImage {
  position: relative;
  margin-top: 80px; }

.featuredImage + .row {
  margin-top: 40px; }

.mainContent .bodyContent {
  margin-top: 60px;
  padding: 60px 40px; }

.pageIntro {
  margin-top: 0; }
  .pageIntro p {
    font-family: "Nunito", sans-serif;
    color: #4C6172;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    margin-top: 0; }

.content-blocks-container {
  position: relative; }
  .content-blocks-container .content-block {
    position: relative; }
    .content-blocks-container .content-block h2 {
      font-family: "jubilat", serif;
      color: #79B829; }
    .content-blocks-container .content-block h3 {
      color: #79B829; }
    .content-blocks-container .content-block h4 {
      color: #4C6172; }
    .content-blocks-container .content-block h5 {
      color: #4C6172; }
    .content-blocks-container .content-block h6 {
      color: #4C6172; }
    .content-blocks-container .content-block p {
      color: #333; }
    .content-blocks-container .content-block .content-heading {
      font-size: 24px;
      line-height: 24px;
      font-family: "jubilat", serif;
      font-weight: 500;
      margin: 0;
      margin-bottom: 10px; }
    .content-blocks-container .content-block .content-subtitle {
      font-size: 18px;
      line-height: 18px;
      color: #4C6172;
      margin-bottom: 15px;
      font-weight: 500; }
    .content-blocks-container .content-block p, .content-blocks-container .content-block li {
      margin-top: 15px;
      font-size: 1.125rem;
      line-height: 1.425rem;
      font-family: "Nunito", sans-serif;
      color: #333; }
      .content-blocks-container .content-block p a, .content-blocks-container .content-block li a {
        font-family: "Nunito", sans-serif;
        color: #333;
        text-decoration: underline;
        transition: 0.2s ease-in-out;
        font-style: italic; }
        .content-blocks-container .content-block p a:hover, .content-blocks-container .content-block li a:hover {
          color: #009EE3; }
    .content-blocks-container .content-block ul, .content-blocks-container .content-block ol {
      padding-left: 20px; }
  .content-blocks-container .content-block + .content-block {
    margin-top: 40px; }
  .content-blocks-container .gallery-block .galleryContainer {
    height: 400px; }
    .content-blocks-container .gallery-block .galleryContainer .galleryImage {
      height: 400px;
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat; }
    .content-blocks-container .gallery-block .galleryContainer .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      background: #009EE3;
      transition: 0.2s ease-in-out;
      height: 50px;
      width: 50px;
      border-radius: 0;
      color: transparent; }
      .content-blocks-container .gallery-block .galleryContainer .slick-arrow:hover {
        background: #0068B3;
        cursor: pointer; }
      .content-blocks-container .gallery-block .galleryContainer .slick-arrow:before {
        position: absolute;
        color: #FFF;
        font-family: "Ionicons";
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center; }
      .content-blocks-container .gallery-block .galleryContainer .slick-arrow.slick-prev {
        left: 20px; }
        .content-blocks-container .gallery-block .galleryContainer .slick-arrow.slick-prev:before {
          content: "\f27d"; }
      .content-blocks-container .gallery-block .galleryContainer .slick-arrow.slick-next {
        right: 20px; }
        .content-blocks-container .gallery-block .galleryContainer .slick-arrow.slick-next:before {
          content: "\f287"; }
    .content-blocks-container .gallery-block .galleryContainer .slick-dots {
      position: absolute;
      left: 10px;
      bottom: 10px;
      list-style-type: none;
      margin: 0;
      text-align: center;
      float: left;
      display: inline-block; }
      .content-blocks-container .gallery-block .galleryContainer .slick-dots li {
        float: left;
        text-align: left;
        display: inline-block;
        opacity: 0.3; }
        .content-blocks-container .gallery-block .galleryContainer .slick-dots li.slick-active {
          opacity: 1; }
        .content-blocks-container .gallery-block .galleryContainer .slick-dots li button {
          font-size: 0;
          width: 10px;
          height: 10px;
          background: #FFF;
          border-radius: 100%; }
      .content-blocks-container .gallery-block .galleryContainer .slick-dots li + li {
        margin-left: 10px; }
  .content-blocks-container .video-block iframe {
    width: 100%; }
  .content-blocks-container .quote-block blockquote {
    font-style: italic;
    color: #79B829;
    font-family: "jubilat", serif;
    font-size: 28px;
    line-height: 36px;
    margin: 0; }
  .content-blocks-container .quote-block .author {
    color: #333;
    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 5px;
    text-align: right;
    padding-right: 20px; }
  .content-blocks-container .quote-block .slick-dots {
    position: absolute;
    left: 0;
    bottom: 10px;
    list-style-type: none;
    margin: 0;
    text-align: center;
    float: left;
    display: inline-block; }
    .content-blocks-container .quote-block .slick-dots li {
      float: left;
      text-align: left;
      display: inline-block;
      opacity: 0.3; }
      .content-blocks-container .quote-block .slick-dots li.slick-active {
        opacity: 1; }
      .content-blocks-container .quote-block .slick-dots li button {
        font-size: 0;
        width: 10px;
        height: 10px;
        background: #F0F0F1;
        border-radius: 100%; }
    .content-blocks-container .quote-block .slick-dots li + li {
      margin-left: 10px; }
  .content-blocks-container .quoteContainer.hideDots .slick-dots {
    display: none; }
  .content-blocks-container .downloads-block .downloadsTable {
    border: 1px solid #F0F0F1;
    box-shadow: 0 4px 8px #F0F0F1;
    width: 100%;
    border-collapse: collapse; }
    .content-blocks-container .downloads-block .downloadsTable tr {
      border: none; }
      .content-blocks-container .downloads-block .downloadsTable tr th {
        color: #333; }
      .content-blocks-container .downloads-block .downloadsTable tr td {
        padding: 10px;
        border: none;
        font-family: "Nunito", sans-serif; }
        .content-blocks-container .downloads-block .downloadsTable tr td.downloads-table-icons i {
          font-size: 24px;
          text-align: center;
          margin: 0 auto;
          display: block; }
        .content-blocks-container .downloads-block .downloadsTable tr td a {
          font-size: 16px;
          line-height: 18px;
          font-family: "Nunito", sans-serif;
          color: #333;
          font-weight: 400;
          text-decoration: none;
          transition: 0.2s ease-in-out; }
          .content-blocks-container .downloads-block .downloadsTable tr td a:hover {
            color: #009EE3; }
    .content-blocks-container .downloads-block .downloadsTable tr:nth-child(odd) {
      background: #FFF; }
    .content-blocks-container .downloads-block .downloadsTable tr:nth-child(even) {
      background: #F0F0F1; }

.welcomeContainer {
  padding: 20px 0; }
  .welcomeContainer .welcome-title {
    position: relative; }
    .welcomeContainer .welcome-title:before {
      content: '';
      position: absolute;
      left: 0;
      top: -20px;
      width: 40px;
      height: 5px;
      background: #009EE3; }
    .welcomeContainer .welcome-title h2 {
      font-size: 28px;
      line-height: 29px;
      color: #333; }
  .welcomeContainer p {
    font-size: 16px;
    line-height: 18px;
    font-family: "jubilat", serif;
    color: #333; }
    @media screen and (min-width: 40em) {
      .welcomeContainer p {
        padding-right: 20px; } }
  .welcomeContainer .welcome-image {
    position: relative; }
    @media screen and (max-width: 39.9375em) {
      .welcomeContainer .welcome-image {
        width: 90%;
        margin: 20px auto 0; } }
    .welcomeContainer .welcome-image:before, .welcomeContainer .welcome-image:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px; }
    .welcomeContainer .welcome-image:before {
      top: 0;
      left: -20px;
      border-top: 5px solid;
      border-left: 5px solid;
      border-color: #009EE3; }
    .welcomeContainer .welcome-image:after {
      bottom: -20px;
      right: -20px;
      border-bottom: 5px solid;
      border-right: 5px solid;
      border-color: #FF0000; }
    .welcomeContainer .welcome-image img {
      margin-top: 20px;
      box-shadow: 0 4px 8px #F0F0F1; }

.pageTitle {
  margin-top: 60px; }

.pageArticle h1, .pageTitle h1 {
  font-size: 40px;
  color: #333;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
  position: relative;
  margin-bottom: 0; }
  @media screen and (min-width: 40em) {
    .pageArticle h1, .pageTitle h1 {
      font-size: 78px;
      line-height: 80px; } }

.neverthelessContainer {
  margin-top: 80px;
  background: #79B829;
  position: relative;
  padding: 60px 0;
  overflow: hidden; }
  .neverthelessContainer h2 {
    color: #FFF;
    font-family: "jubilat", serif;
    font-style: italic;
    font-size: 40px;
    line-height: 42px;
    font-weight: 500;
    margin: 0; }
  .neverthelessContainer p {
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: #FFF;
    margin-top: 20px; }
  .neverthelessContainer .btn {
    margin-top: 20px; }
  .neverthelessContainer .bookContainer {
    position: relative;
    margin-top: 40px; }
  @media screen and (min-width: 40em) {
    .neverthelessContainer {
      min-height: 450px; }
      .neverthelessContainer .bookContainer {
        right: -20%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 70%;
        margin: 0; } }
  @media screen and (min-width: 64em) {
    .neverthelessContainer .bookContainer {
      right: -30%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 80%;
      margin: 0; } }

.slideshow {
  height: 500px;
  position: relative; }
  @media screen and (min-width: 40em) {
    .slideshow {
      height: 580px; } }
  .slideshow .slide {
    height: 500px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative; }
    .slideshow .slide:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(76, 97, 114, 0.25) 0%, rgba(76, 97, 114, 0.6) 60%, rgba(76, 97, 114, 0.75) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#404c6172', endColorstr='#bf4c6172',GradientType=0 ); }
    .slideshow .slide .btn {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #FFF;
      color: #79B829;
      font-family: "jubilat", serif;
      font-style: italic;
      padding: 15px 60px 15px 40px;
      border-radius: 0; }
      .slideshow .slide .btn:after {
        content: "\f119";
        font-family: "Ionicons";
        font-style: normal;
        font-size: 20px;
        position: absolute;
        right: 20px;
        width: 20px;
        height: 20px;
        color: #79B829;
        transition: 0.2s ease-in-out; }
      .slideshow .slide .btn:hover:after {
        right: 10px; }
    @media screen and (min-width: 40em) {
      .slideshow .slide {
        height: 580px; }
        .slideshow .slide .row {
          height: 100%; }
          .slideshow .slide .row .columns {
            height: 100%;
            position: relative; } }
    .slideshow .slide .contentContainer {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 20px; }
      @media screen and (min-width: 40em) {
        .slideshow .slide .contentContainer {
          height: 100%; } }
      .slideshow .slide .contentContainer .slideContent {
        position: absolute;
        width: 100%;
        height: auto;
        top: 50%;
        transform: translateY(-50%); }
        @media screen and (max-width: 39.9375em) {
          .slideshow .slide .contentContainer .slideContent {
            width: 90%; } }
      .slideshow .slide .contentContainer h2 {
        color: #FFF;
        font-size: 2.3rem;
        line-height: 2.3rem;
        font-weight: 500;
        font-style: italic;
        font-family: "jubilat", serif;
        margin: 0; }
        @media screen and (min-width: 40em) {
          .slideshow .slide .contentContainer h2 {
            font-size: 40px;
            line-height: 44px;
            margin-top: 0; } }
        @media screen and (min-width: 64em) {
          .slideshow .slide .contentContainer h2 {
            font-size: 60px;
            line-height: 62px;
            margin-top: 0; } }
      .slideshow .slide .contentContainer .bannerSubtitle {
        color: #FFF;
        font-size: 18px;
        font-family: "Nunito", sans-serif;
        margin: 0; }
        @media screen and (min-width: 40em) {
          .slideshow .slide .contentContainer .bannerSubtitle {
            margin-top: 20px;
            line-height: 22px;
            font-size: 22px; } }
    .slideshow .slide h4 {
      font-family: "Nunito", sans-serif;
      font-size: 22px;
      color: #FFF;
      font-style: italic;
      position: absolute;
      bottom: 20px;
      right: 0;
      left: 0;
      text-align: center;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
      width: 90%; }
      @media screen and (min-width: 40em) {
        .slideshow .slide h4 {
          font-size: 28px;
          max-width: 62.5rem;
          width: 100%;
          bottom: 0;
          text-align: right; } }
  .slideshow .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: #4C6172;
    transition: 0.2s ease-in-out;
    height: 50px;
    width: 50px;
    border-radius: 0;
    color: transparent; }
    .slideshow .slick-arrow:hover {
      background: #3d5263;
      cursor: pointer; }
    .slideshow .slick-arrow:before {
      position: absolute;
      color: #FFF;
      font-family: "Ionicons";
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center; }
    .slideshow .slick-arrow.slick-prev {
      left: 20px; }
      .slideshow .slick-arrow.slick-prev:before {
        content: "\f27d"; }
    .slideshow .slick-arrow.slick-next {
      right: 20px; }
      .slideshow .slick-arrow.slick-next:before {
        content: "\f287"; }
    @media screen and (max-width: 39.9375em) {
      .slideshow .slick-arrow {
        display: none !important; } }
  .slideshow .slick-dots {
    position: absolute;
    left: 20px;
    bottom: 20px;
    margin: 0 auto;
    text-align: left;
    padding: 0;
    width: 250px; }
    @media screen and (min-width: 40em) {
      .slideshow .slick-dots {
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center; } }
    .slideshow .slick-dots li {
      text-align: center;
      display: inline-block; }
      .slideshow .slick-dots li.slick-active button {
        opacity: 1; }
      .slideshow .slick-dots li button {
        color: transparent;
        background: #FFF;
        opacity: 0.6;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        transition: 0.2s ease-in-out; }
      .slideshow .slick-dots li:hover button {
        cursor: pointer;
        opacity: 1; }
    .slideshow .slick-dots li + li {
      margin-left: 15px; }

.bannerContainer {
  height: 350px;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat; }
  .bannerContainer:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(76, 97, 114, 0.25) 0%, rgba(76, 97, 114, 0.6) 60%, rgba(76, 97, 114, 0.75) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#404c6172', endColorstr='#bf4c6172',GradientType=0 ); }
  @media screen and (min-width: 40em) {
    .bannerContainer {
      height: 400px;
      background-position: 50% 25%; } }
  .bannerContainer .row {
    height: 100%; }
    .bannerContainer .row .columns {
      height: 100%;
      position: relative; }
  .bannerContainer .contentContainer {
    z-index: 11;
    position: absolute;
    bottom: 40px; }
    .bannerContainer .contentContainer h2 {
      color: #FFF;
      font-size: 2.3rem;
      line-height: 2.3rem;
      font-weight: 500;
      font-style: italic;
      font-family: "jubilat", serif;
      margin: 0; }
      @media screen and (min-width: 40em) {
        .bannerContainer .contentContainer h2 {
          font-size: 40px;
          line-height: 44px;
          margin-top: 0; } }
      @media screen and (min-width: 64em) {
        .bannerContainer .contentContainer h2 {
          font-size: 45px;
          line-height: 45px;
          margin-top: 0; }
          .bannerContainer .contentContainer h2.noSubtitle {
            font-size: 56px;
            line-height: 56px; } }
    .bannerContainer .contentContainer .postDate {
      color: #FFF;
      font-weight: 500; }
    .bannerContainer .contentContainer .bannerSubtitle {
      color: #FFF;
      font-size: 18px;
      font-family: "Nunito", sans-serif;
      margin: 0; }
      @media screen and (min-width: 40em) {
        .bannerContainer .contentContainer .bannerSubtitle {
          margin-top: 20px;
          line-height: 22px;
          font-size: 22px; } }
    @media screen and (min-width: 40em) {
      .bannerContainer .contentContainer p {
        padding-right: 100px; } }

.defaultBanner {
  position: relative;
  background: #79B829;
  height: 300px; }
  .defaultBanner .contentContainer {
    position: absolute;
    bottom: 0;
    color: #FFF; }
    .defaultBanner .contentContainer h2 {
      margin-top: 0;
      color: #FFF;
      font-size: 48px;
      line-height: 48px; }

form#mc-embedded-subscribe-form {
  padding: 0; }

form .errors {
  list-style-type: none;
  padding: 15px 20px;
  border: 2px solid #FF0000; }
  form .errors li {
    color: #FF0000;
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    margin-top: 0; }

form input + .errors {
  margin-top: 0;
  margin-bottom: 40px; }

form select + .errors {
  margin-top: 0;
  margin-bottom: 40px; }

form textarea + .errors {
  margin-top: 0;
  margin-bottom: 40px; }

form .mce_inline_error {
  background: #FF0000;
  font-family: "jubilat", serif;
  text-transform: uppercase; }

form input.mce_inline_error {
  background: none;
  border: 2px solid #FF0000; }

form input[type="text"], form input[type="email"], form input[type="password"], form input[type="number"] {
  height: 45px;
  margin-bottom: 20px;
  border-radius: 0 !important;
  background-color: #FFF; }

form .indicates-required {
  font-size: 12px;
  margin-bottom: 10px; }

form .asterisk {
  font-size: 16px;
  position: relative;
  top: 5px;
  color: #FF0000; }

form label {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.125rem;
  margin-bottom: 5px;
  font-family: "Nunito", sans-serif;
  color: #333; }

form .response {
  margin-bottom: 40px;
  font-family: "jubilat", serif; }

.capForm {
  margin-top: 60px;
  transition: 0.2s ease-in-out; }
  .capForm.disabled {
    pointer-events: none;
    opacity: 0.5; }

select {
  background-color: #FFF;
  border-color: #F0F0F1;
  border-radius: 0;
  position: relative; }
  select:after {
    content: "\f3d0";
    font-family: "Ionicons";
    position: absolute;
    top: 15px;
    right: 15px;
    color: #333; }

textarea {
  background-color: #FFF; }

.signupForm {
  margin-top: 80px; }

.thankYou {
  font-family: "Nunito", sans-serif;
  color: #333;
  font-size: 24px;
  line-height: 30px;
  font-style: italic;
  text-align: center; }

input[type="submit"], button[type="submit"], .enupal-stripe-button {
  outline: none !important;
  background: #79B829 !important;
  color: #FFF !important;
  padding: 15px 30px !important;
  transition: 0.2s ease-in-out !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  font-family: "Nunito", sans-serif !important;
  display: inline-block !important;
  outline: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 20px 0 0 !important;
  cursor: pointer; }
  input[type="submit"]:hover, button[type="submit"]:hover, .enupal-stripe-button:hover {
    background: #6ea724 !important; }

.enupal-stripe-button span {
  font-weight: 500 !important;
  font-size: 18px !important;
  background: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
  height: auto !important;
  line-height: inherit !important;
  padding: 0 !important; }

.thanksBtn {
  display: block;
  text-align: center; }

#thanks {
  visibility: hidden; }

.submissionSuccess {
  display: none;
  border: 5px solid #79B829;
  padding: 10px 20px;
  margin-top: 60px; }
  .submissionSuccess h2 {
    color: #79B829;
    font-style: italic;
    font-size: 1.6rem;
    line-height: 1.6rem; }
  .submissionSuccess h4 {
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #4C6172;
    font-family: "Nunito", sans-serif; }
  .submissionSuccess.show {
    display: block; }

.moneyCourse_iframe iframe {
  width: 100%;
  padding: 20px 0;
  margin: 0;
  position: relative;
  min-height: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  height: 960px; }

table thead {
  border-bottom: 1px solid #F0F0F1; }
  table thead th {
    padding: 10px;
    text-align: left; }

.testimonial {
  position: relative;
  margin-top: 40px;
  color: #333; }

#downloadBrochure {
  position: relative;
  margin-top: 120px; }
  #downloadBrochure .brochureContainer {
    background: #333;
    position: relative; }
  #downloadBrochure .brochureImage {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: none; }
    @media screen and (min-width: 40em) {
      #downloadBrochure .brochureImage {
        width: 50%;
        display: block; } }
  #downloadBrochure .brochureContent {
    padding: 50px 20px; }
    @media screen and (min-width: 40em) {
      #downloadBrochure .brochureContent {
        padding: 50px 0 50px 40px; } }
    #downloadBrochure .brochureContent h4 {
      color: #FFF;
      font-size: 38px;
      line-height: 38px; }
    #downloadBrochure .brochureContent p {
      color: #FFF;
      font-size: 14px; }
    #downloadBrochure .brochureContent .btn {
      margin-top: 10px; }
    @media screen and (min-width: 40em) {
      #downloadBrochure .brochureContent {
        padding-right: 80px; } }

.sidebarNavigation {
  background: #4C6172; }
  .sidebarNavigation .menu {
    padding: 20px 0; }
    .sidebarNavigation .menu ul {
      padding: 0;
      width: 100%;
      margin: 0; }
      .sidebarNavigation .menu ul > li {
        display: none; }
      .sidebarNavigation .menu ul .subItem-active {
        display: block; }
        .sidebarNavigation .menu ul .subItem-active > a {
          color: #FFF;
          font-family: "jubilat", serif;
          font-weight: 500;
          font-style: italic;
          font-size: 18px; }
        .sidebarNavigation .menu ul .subItem-active ul {
          margin: 0; }
          .sidebarNavigation .menu ul .subItem-active ul li {
            display: block;
            font-family: "Nunito", sans-serif; }
            .sidebarNavigation .menu ul .subItem-active ul li a {
              display: block;
              color: #FFF;
              transition: 0.2s ease-in-out; }
              .sidebarNavigation .menu ul .subItem-active ul li a:hover {
                padding-left: 30px; }
            .sidebarNavigation .menu ul .subItem-active ul li.current-menu-item a {
              padding-left: 30px;
              position: relative; }
              .sidebarNavigation .menu ul .subItem-active ul li.current-menu-item a:before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 15px;
                height: 5px;
                width: 5px;
                background: #79B829; }

.pageControls {
  display: block;
  clear: both;
  margin-bottom: 30px;
  margin-right: 15px;
  text-align: right; }
  .pageControls .pagination {
    display: inline-block; }
    .pageControls .pagination.arrow {
      color: transparent;
      font-size: 0;
      margin: 0; }
      .pageControls .pagination.arrow:before {
        font-family: "Ionicons";
        color: #4C6172;
        font-size: 20px;
        line-height: 20px; }
      .pageControls .pagination.arrow.prevPage {
        margin-right: 10px; }
        .pageControls .pagination.arrow.prevPage:before {
          content: "\f27d"; }
      .pageControls .pagination.arrow.nextPage {
        margin-left: 10px; }
        .pageControls .pagination.arrow.nextPage:before {
          content: "\f287"; }
      .pageControls .pagination.arrow.disable {
        pointer-events: none;
        opacity: 0.5; }
    .pageControls .pagination.pageDots {
      width: 7px;
      height: 7px;
      background: #4C6172;
      opacity: 0.5;
      color: transparent;
      font-size: 0;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      margin: 0;
      position: relative;
      top: -14px; }
      .pageControls .pagination.pageDots:hover {
        background: #4C6172;
        opacity: 1; }
      .pageControls .pagination.pageDots.currentPage {
        background: #79B829;
        opacity: 1; }
  .pageControls .pageDots + .pageDots {
    margin-left: 2px; }

.alwaysHope {
  padding-top: 80px;
  position: relative; }
  .alwaysHope:before {
    content: '';
    position: absolute;
    background-image: url("/assets/img/site/usa-dots-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 10%;
    top: 60px;
    left: 0;
    z-index: -1; }
  @media screen and (min-width: 40em) {
    .alwaysHope:before {
      height: 100%; } }
  .alwaysHope .capStepsContainer {
    margin-top: 40px; }
    .alwaysHope .capStepsContainer .capStep {
      border: 1px solid #F0F0F1;
      padding: 30px 30px 20px;
      transition: 0.2s ease-in-out;
      background: #FFF;
      position: relative; }
      @media screen and (min-width: 40em) {
        .alwaysHope .capStepsContainer .capStep {
          padding: 40px 30px;
          min-height: 300px; } }
      @media screen and (max-width: 39.9375em) {
        .alwaysHope .capStepsContainer .capStep .iconContainer {
          width: 40%;
          margin: 0 auto; } }
      .alwaysHope .capStepsContainer .capStep .iconContainer .stepIcon {
        fill: #79B829; }
      .alwaysHope .capStepsContainer .capStep .stepLink {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0; }
      .alwaysHope .capStepsContainer .capStep img {
        margin: 0 auto;
        display: block;
        max-width: 50%; }
      .alwaysHope .capStepsContainer .capStep .stepTitle {
        margin: 0;
        font-family: "Nunito", sans-serif;
        color: #333;
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        margin-top: 20px; }
        @media screen and (min-width: 40em) {
          .alwaysHope .capStepsContainer .capStep .stepTitle {
            font-size: 22px;
            line-height: 24px;
            text-align: left;
            margin-top: 0; } }
      .alwaysHope .capStepsContainer .capStep .stepSubtitle {
        color: #B0B0B0;
        margin-top: 5px;
        margin-bottom: 0;
        text-align: center; }
        @media screen and (min-width: 40em) {
          .alwaysHope .capStepsContainer .capStep .stepSubtitle {
            text-align: left; } }
      .alwaysHope .capStepsContainer .capStep .stepDescription {
        color: #B0B0B0;
        font-size: 1rem;
        text-align: center; }
        @media screen and (min-width: 40em) {
          .alwaysHope .capStepsContainer .capStep .stepDescription {
            text-align: left; } }
      .alwaysHope .capStepsContainer .capStep:hover {
        background: #4C6172;
        border-color: #4C6172; }
        .alwaysHope .capStepsContainer .capStep:hover .stepTitle, .alwaysHope .capStepsContainer .capStep:hover .stepSubtitle, .alwaysHope .capStepsContainer .capStep:hover .stepDescription {
          color: #FFF; }
        .alwaysHope .capStepsContainer .capStep:hover .iconContainer .stepIcon {
          fill: #FFF;
          stroke: #FFF; }
    .alwaysHope .capStepsContainer .columns + .columns .capStep {
      margin-top: 20px; }
      @media screen and (min-width: 40em) {
        .alwaysHope .capStepsContainer .columns + .columns .capStep {
          margin-top: 0;
          border-left: none; } }
  .alwaysHope .btn {
    margin-top: 40px; }

.imageBlocks {
  width: 100%;
  margin-top: 80px; }
  @media screen and (min-width: 40em) {
    .imageBlocks {
      margin-top: 120px; } }
  @media screen and (min-width: 40em) {
    .imageBlocks .imageBlock:nth-child(odd) .imgColumn {
      float: left; }
    .imageBlocks .imageBlock:nth-child(odd) .textColumn {
      float: right; }
    .imageBlocks .imageBlock:nth-child(even) .imgColumn {
      float: right; }
    .imageBlocks .imageBlock:nth-child(even) .textColumn {
      float: left; } }
  .imageBlocks .imageBlock .textColumn {
    min-height: 500px;
    background: #F8F8F8;
    padding: 40px 20px; }
    .imageBlocks .imageBlock .textColumn h2 {
      font-style: italic;
      font-weight: 500;
      font-size: 32px;
      line-height: 36px; }
    @media screen and (min-width: 40em) {
      .imageBlocks .imageBlock .textColumn {
        padding: 60px 80px 60px 40px; } }
    .imageBlocks .imageBlock .textColumn .btn {
      margin-top: 20px; }
  .imageBlocks .imageBlock .imgColumn {
    background-size: cover;
    background-position: 50% 50%;
    position: relative; }
    .imageBlocks .imageBlock .imgColumn:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: #4C6172;
      opacity: 0.6; }
    @media screen and (max-width: 39.9375em) {
      .imageBlocks .imageBlock .imgColumn {
        height: 400px !important; } }

.featuredTestimonial {
  background: #F8F8F8;
  padding: 0 0 80px; }
  @media screen and (min-width: 40em) {
    .featuredTestimonial {
      padding: 80px 0; } }
  .featuredTestimonial iframe {
    width: 100%; }
  .featuredTestimonial .columns {
    position: relative;
    height: 100%; }
  .featuredTestimonial p {
    font-family: "jubilat", serif;
    font-weight: 300;
    font-style: italic;
    font-size: 1.2rem;
    line-height: 1.4rem; }
  @media screen and (min-width: 40em) {
    .featuredTestimonial p {
      padding-left: 20px; }
    .featuredTestimonial span {
      padding-left: 20px; } }

.authorImg {
  width: 40px;
  border-radius: 100%;
  position: relative;
  top: -2px;
  left: 10px; }

.featuredBlocks .featureBlock {
  padding: 30px 0; }
  .featuredBlocks .featureBlock h2 {
    color: #79B829;
    font-family: "jubilat", serif;
    font-size: 26px;
    margin-bottom: 0; }
  .featuredBlocks .featureBlock h5 {
    color: #4C6172;
    font-family: "Nunito", sans-serif;
    margin-top: 0;
    font-size: 18px; }

.latestNews {
  margin-top: 80px; }
  .latestNews .homepageNews .newsInclude {
    margin-top: 40px;
    background: #FFF;
    padding-top: 0; }

.featuredArticle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
  width: 100%;
  position: relative; }
  .featuredArticle:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(76, 97, 114, 0.25) 0%, rgba(76, 97, 114, 0.6) 60%, rgba(76, 97, 114, 0.75) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#404c6172', endColorstr='#bf4c6172',GradientType=0 ); }
  .featuredArticle .featuredArticle_Content {
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .featuredArticle .featuredArticle_Content h2 {
      color: #FFF;
      font-size: 42px;
      line-height: 42px;
      margin: 0;
      font-style: italic;
      font-weight: 500; }
    .featuredArticle .featuredArticle_Content p {
      color: #FFF;
      margin-top: 15px;
      display: inline-block; }
    .featuredArticle .featuredArticle_Content .articleLink {
      display: inline-block;
      margin-left: 40px;
      color: #FFF;
      position: relative;
      font-family: "Nunito", sans-serif;
      text-decoration: none;
      font-weight: 500;
      font-size: 1.125rem; }
      .featuredArticle .featuredArticle_Content .articleLink:after {
        content: "\f119";
        font-family: "Ionicons";
        font-style: normal;
        font-size: 20px;
        position: absolute;
        right: -25px;
        width: 20px;
        height: 20px;
        color: #FFF;
        transition: 0.2s ease-in-out; }
      .featuredArticle .featuredArticle_Content .articleLink:hover:after {
        right: -35px; }

@media screen and (min-width: 40em) {
  .articlesContainer .columns:nth-child(1) .newsArticles {
    height: 480px; } }

@media screen and (min-width: 40em) {
  .articlesContainer .columns:nth-child(2) {
    width: 66.6666%; } }

.newsArticles {
  transition: .2s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  overflow: hidden;
  height: 225px;
  margin-bottom: 30px;
  background-color: #4C6172; }
  .newsArticles .articleLink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 111; }
  .newsArticles .articleContent {
    padding: 30px 20px;
    height: 100%;
    width: 100%;
    position: relative;
    transition: 0.2s ease-in-out; }
    .newsArticles .articleContent:after {
      content: '';
      position: absolute;
      box-shadow: inset 0px -50px 35px 19px rgba(61, 82, 99, 0.75);
      height: 100%;
      width: 160%;
      left: -30%;
      right: 0;
      top: 0;
      margin: 0 auto;
      z-index: 0;
      transition: 0.2s ease-in-out; }
    .newsArticles .articleContent .postDate {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 11;
      color: #FFF;
      font-family: "Nunito", sans-serif;
      text-align: right;
      font-size: 14px; }
    .newsArticles .articleContent h3 {
      font-family: "Nunito", sans-serif;
      line-height: 1.1;
      font-size: 1.5rem;
      font-weight: 300;
      color: #FFF;
      position: absolute;
      bottom: 20px;
      left: 20px;
      padding: 0 20px 0 0;
      margin: 0;
      z-index: 11;
      transition: 0.2s ease-in-out; }
  .newsArticles:hover {
    cursor: pointer; }
    .newsArticles:hover .articleContent h3 {
      bottom: 40px; }
    .newsArticles:hover .articleContent:after {
      box-shadow: inset 0px -50px 35px 30px rgba(61, 82, 99, 0.75); }

.newsArticle .articleHeading {
  margin-top: 80px; }
  .newsArticle .articleHeading h1 {
    font-size: 80px;
    line-height: 82px;
    color: #333;
    margin: 0;
    margin-bottom: 10px; }
    @media screen and (min-width: 40em) {
      .newsArticle .articleHeading h1 {
        padding-right: 40px; } }
  .newsArticle .articleHeading .postDate {
    font-family: "Nunito", sans-serif;
    color: #333;
    font-size: 14px; }

.donationContainer .donationForm {
  margin-top: 40px;
  margin-bottom: 80px; }
  .donationContainer .donationForm h2 {
    text-align: center;
    font-family: "jubilat", serif;
    font-style: italic;
    font-weight: 500;
    color: #79B829;
    margin-bottom: 0; }
  .donationContainer .donationForm p {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.1rem;
    color: #4C6172; }
  .donationContainer .donationForm form {
    margin-top: 40px; }
  .donationContainer .donationForm.monthlyDonation {
    display: none; }
    .donationContainer .donationForm.monthlyDonation.active {
      display: block; }
  .donationContainer .donationForm.oneoffDonation {
    display: none; }
    .donationContainer .donationForm.oneoffDonation.active {
      display: block; }

.ourTeam {
  margin-top: 40px; }
  .ourTeam h2 {
    font-family: "jubilat", serif;
    color: #79B829;
    font-size: 40px;
    line-height: 42px; }
  .ourTeam .staffPhoto {
    background: #F8F8F8;
    border-radius: 100%;
    transition: 0.2s ease-in-out;
    position: relative;
    cursor: pointer;
    max-width: 300px;
    outline: none; }
    .ourTeam .staffPhoto img {
      border-radius: 100%; }
    .ourTeam .staffPhoto:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: #3d5263;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 100%;
      transition: 0.2s ease-in-out;
      pointer-events: none; }
    .ourTeam .staffPhoto:hover {
      transform: scale(0.97); }
      .ourTeam .staffPhoto:hover:before {
        opacity: 0.5; }
  .ourTeam .staffInfo {
    margin-bottom: 40px; }
    .ourTeam .staffInfo h5 {
      font-family: "Nunito", sans-serif;
      color: #79B829;
      font-size: 18px;
      margin-bottom: 0; }
    .ourTeam .staffInfo span {
      color: #4C6172;
      text-transform: uppercase;
      font-family: "Nunito", sans-serif;
      font-size: 14px;
      line-height: 14px; }

.reveal-overlay {
  top: 0;
  border-top: 1px solid #F0F0F1;
  z-index: 1111; }

.reveal {
  overflow: scroll;
  width: 100%; }
  @media screen and (min-width: 40em) {
    .reveal {
      width: 75%;
      height: 400px; } }
  .reveal .staffPhoto {
    position: relative;
    background: #F8F8F8;
    border-radius: 100%;
    max-width: 300px; }
    .reveal .staffPhoto img {
      border-radius: 100%; }
  .reveal .medium-8 {
    overflow: scroll; }
  .reveal .revealContent {
    padding: 0 20px 20px;
    overflow: scroll; }
    .reveal .revealContent h3 {
      font-family: "Nunito", sans-serif;
      color: #79B829;
      font-weight: 500;
      font-size: 32px;
      line-height: 32px; }
    .reveal .revealContent .lead {
      color: #4C6172;
      text-transform: uppercase;
      font-family: "Nunito", sans-serif;
      font-size: 14px;
      line-height: 14px;
      font-weight: 700; }
    .reveal .revealContent p {
      font-size: 1.125rem; }
  .reveal .close-button {
    right: 15px;
    top: 15px;
    margin: 0;
    left: unset; }
    @media screen and (min-width: 40em) {
      .reveal .close-button {
        margin: 0 auto; } }
    .reveal .close-button span {
      position: relative;
      top: -2px; }

/* Small only */
/* Medium and up */
/* Medium only */
/* Large and up */
/* Large only */
